import Link from "next/link"
import { useEffect, useRef, useState } from "react"

import { useGlobalContext } from "@context/global"
import Player from "@vimeo/player"
import { AnimatePresence, motion } from "framer-motion"

import { LocaleSwitcher } from "@components/locale-switcher"
import Image from "@components/shared/AppImage"
import { Wysiwyg } from "@components/shared/Wysiwyg"
import { MenuIcon } from "@icons/MenuIcon"
import { RightArrowIcon } from "@icons/RightArrow"
import { AMFLogoWhite } from "@logos/AMFLogoWhite"
import { JG24LogoDesktop } from "@logos/JG24LogoDesktop"
import { JG24LogoMobile } from "@logos/JG24LogoMobile"
import { BackgroundType, WPMedia } from "@models/common"
import { MoodDistrictPattern } from "@patterns/MoodDistrictPattern"
import { useGlobalStore } from "@store/globalStore"

export interface LandingProps {
  backgroundType: BackgroundType
  videoId: string
  videoThumbnail: WPMedia
  bgImage: WPMedia
  title: string
  description: string
  menuBtnLabel: string
}

export const Landing = ({
  backgroundType,
  videoId,
  videoThumbnail,
  bgImage,
  title,
  description,
  menuBtnLabel,
}: LandingProps) => {
  const toggleMenu = useGlobalStore(state => state.toggleMenu)
  const { bokaVisning } = useGlobalContext()
  const containerRef = useRef<HTMLDivElement>(null)
  const player = useRef<Player>()
  const [isPlaying, setIsPlaying] = useState(false)

  useEffect(() => {
    if (backgroundType === "image") return

    player.current = new Player(containerRef.current!, {
      id: +videoId,
      background: true,
    })

    player.current?.on("play", () => {
      setIsPlaying(true)
    })

    return () => {
      player.current?.off("play")
      player.current?.destroy()
    }
  }, [backgroundType, videoId])

  return (
    <div className="relative flex h-full w-screen">
      {/* Base : Background Video or Background Image */}
      {backgroundType === "video" && (
        <div className="relative w-full">
          <AnimatePresence initial={false}>
            {!isPlaying && (
              <motion.div
                key="videoThumbnail"
                exit={{ opacity: 0 }}
                transition={{ ease: "easeOut", duration: 0.8, delay: 0.25 }}
                className="absolute inset-0 z-10 h-full w-full"
              >
                <Image
                  className="object-cover"
                  src={videoThumbnail.mediaItemUrl}
                  alt={videoThumbnail.altText}
                  fill
                />
              </motion.div>
            )}
          </AnimatePresence>

          <div ref={containerRef} className="landing-vimeo"></div>
        </div>
      )}

      {backgroundType === "image" && (
        <div className="relative w-full">
          <Image className="object-cover" src={bgImage.mediaItemUrl} alt={bgImage.altText} fill />
        </div>
      )}

      {/* Overlay */}
      <div className="absolute inset-0 z-10 h-full w-full bg-black bg-opacity-40"></div>
      {/* Gradient Overlay */}
      <div className="absolute bottom-0 z-20 h-[32%] w-full bg-gradient-to-t from-black opacity-60"></div>

      {/* Landing Graphic - Mobile - Top */}
      <div className="absolute left-1/2 top-0 z-30 w-full -translate-x-1/2 -translate-y-[70%] opacity-75 md:hidden">
        <MoodDistrictPattern className="-ml-[10%] h-full w-[120%]" />
      </div>
      {/* Landing Graphic - Mobile - Bottom */}
      <div className="absolute bottom-0 left-1/2 z-30 w-full -translate-x-1/2 translate-y-[40%] opacity-75 md:hidden">
        <MoodDistrictPattern className="-ml-[10%] h-full w-[120%]" />
      </div>
      {/* Landing Graphic - Desktop */}
      <div className="absolute left-1/2 top-1/2 z-30 hidden h-[95%] w-[97%] -translate-x-1/2 -translate-y-1/2 opacity-75 md:block xl:h-auto xl:w-auto">
        <MoodDistrictPattern className="h-full w-full" />
      </div>

      {/* Mood District Logo - Bottom */}
      <div className="absolute bottom-16 left-16 z-30 hidden md:block">
        <AMFLogoWhite className="w-[198px]" />
      </div>

      {/* Landing Texts */}
      <div className="absolute left-1/2 top-1/2 z-40 flex h-full w-full -translate-x-1/2 -translate-y-1/2 flex-col items-center justify-between p-5 pb-5 text-white md:justify-center">
        <div className="flex w-full justify-between md:hidden">
          <AMFLogoWhite className="w-[164px]" />
          <LocaleSwitcher />
        </div>

        <div>
          <h1 className="hidden">{title}</h1>
          <JG24LogoMobile className="mx-auto mb-6 max-w-full md:hidden" />
          <JG24LogoDesktop className="mb-8 mt-24 hidden md:block md:w-[720px] lg:w-[900px] xl:w-[1080px] w1920:h-[100px] w1920:w-[1440px]" />

          <Wysiwyg
            content={description}
            className="app-pre-title mx-auto mt-3 max-w-[335px] text-white md:max-w-full w1920:prose-p:text-link-lg"
          />
          <div className="mt-6 flex justify-center md:mb-24 md:mt-8">
            <Link href={bokaVisning.link} className="flex items-center space-x-1 group">
              <span className="text-[20px]/[24px] font-bold">{bokaVisning.label}</span>
              <RightArrowIcon className="fill-white transition duration-300 group-hover:translate-x-2" />
            </Link>
          </div>
        </div>

        <button
          onClick={toggleMenu.bind(null, "up")}
          className="app-link-lg app-hover group flex w-full items-center justify-center rounded border border-white bg-black bg-opacity-20 px-6 py-5 font-bold uppercase tracking-widest backdrop-blur transition-colors hover:bg-white hover:text-black-olive md:w-auto"
        >
          {menuBtnLabel}
          <MenuIcon className="app-hover ml-4 fill-white transition-colors group-hover:fill-black-olive" />
        </button>
      </div>

      {/* Locale Switcher */}
      <LocaleSwitcher className="absolute bottom-16 right-16 hidden md:block" />
    </div>
  )
}
