import { iconProps } from "./"

export const RightArrowIcon = ({ className }: iconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path d="M14.5 5.803l-1.5 1.5 4.57 4.57H3.5v2h14.07L13 18.444l1.5 1.5 7.07-7.07-7.07-7.07z" />
    </svg>
  )
}
