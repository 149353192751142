import { logoProps } from "./"

export const JG24LogoDesktop = ({ className }: logoProps) => {
  return (
    <svg
      className={className}
      width="1080"
      height="75"
      viewBox="0 0 1080 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1171_444)">
        <path
          d="M25.1821 74.8735C12.1104 74.8735 1.34549 64.9734 0 52.2859L12.7829 50.0756C13.0717 56.7076 18.646 61.8977 25.0857 61.8977C32.1025 61.8977 37.6773 56.2269 37.6773 49.499V15.5699H24.7974V2.5946H50.6526V49.499C50.6526 63.5315 39.2151 74.8735 25.1821 74.8735Z"
          fill="white"
        />
        <path
          d="M97.1697 73.4316V67.2802L95.1517 60.1675H74.5826L72.6605 67.2802V73.4316H59.3005V68.145C59.3005 67.2802 59.3964 66.5113 59.7812 65.7423L78.6196 2.5946H90.6341L109.569 65.55C109.761 66.3189 110.145 67.1842 110.145 68.0491V73.4316H97.1697V73.4316ZM84.675 22.7785L77.9471 47.7688H91.6913L84.675 22.7785Z"
          fill="white"
        />
        <path
          d="M158.2 73.4316V65.55L143.399 41.425L133.114 55.1697V73.4316H120.235V2.5946H133.114V33.7358L156.182 2.5946H171.08L151.184 29.9876L170.503 61.9936C170.791 62.859 171.08 63.8203 171.08 64.8775V73.4316H158.2Z"
          fill="white"
        />
        <path
          d="M203.18 74.8734C189.147 74.8734 177.806 63.5314 177.806 49.4989V26.5271C177.806 12.4941 189.147 1.15259 203.18 1.15259C217.213 1.15259 228.651 12.4941 228.651 26.5271V49.4989C228.651 63.5314 217.213 74.8734 203.18 74.8734ZM215.771 26.623C215.771 19.7027 210.1 14.1284 203.084 14.1284C196.356 14.1284 190.685 19.7991 190.685 26.5271V49.4989C190.685 56.2268 196.356 61.8976 203.18 61.8976C210.1 61.8976 215.771 56.2268 215.771 49.4989V26.623Z"
          fill="white"
        />
        <path
          d="M267.575 73.4316H239.221V2.5946H267.575C277.955 2.5946 286.413 11.0528 286.413 21.5289C286.413 26.0465 284.683 30.2759 281.992 33.6399C286.894 37.8687 290.066 44.0201 290.066 50.8445C290.066 63.3396 279.974 73.4316 267.575 73.4316ZM267.094 15.6663H252.1V28.5457H267.19C270.651 28.5457 273.438 25.5659 273.438 22.0096C273.438 18.4532 270.651 15.6663 267.094 15.6663ZM267.094 40.4637H252.1V60.5522H267.287C272.669 60.5522 277.186 56.131 277.186 50.6521C277.09 44.9814 272.573 40.4637 267.094 40.4637Z"
          fill="white"
        />
        <path
          d="M321.684 74.777C306.402 74.6811 297.751 65.6463 296.309 54.1125L309.189 52.1899L309.477 54.3043C309.862 58.6296 313.803 61.8976 318.224 61.8976H324.759C329.661 61.8976 333.987 57.2841 333.987 52.3823C333.987 47.5764 330.911 43.2511 325.144 41.9056L314.572 39.4065C306.305 37.4844 300.154 29.5069 300.154 20.4716C300.154 9.80314 308.708 1.24854 319.281 1.24854H322.837C333.026 1.24854 341.387 9.22606 341.964 19.4144L329.373 21.2406C329.373 21.2406 328.893 18.6455 328.796 18.2613C328.123 15.8581 325.721 13.936 323.03 13.936H318.32C315.244 13.936 312.553 16.5311 312.553 19.7027V21.337C312.553 24.4127 314.86 26.7195 317.647 27.392L327.547 29.6987C331.488 30.66 335.236 32.5826 338.408 34.9853C343.31 38.8299 346.674 44.5966 346.482 51.9975C346.289 64.3968 336.87 74.777 321.684 74.777Z"
          fill="white"
        />
        <path
          d="M384.445 73.4316H356.091V2.5946H384.445C394.825 2.5946 403.284 11.0528 403.284 21.5289C403.284 26.0465 401.553 30.2759 398.862 33.6399C403.764 37.8687 406.936 44.0201 406.936 50.8445C406.936 63.3396 396.844 73.4316 384.445 73.4316ZM383.965 15.6663H368.97V28.5457H384.06C387.521 28.5457 390.308 25.5659 390.308 22.0096C390.308 18.4532 387.521 15.6663 383.965 15.6663ZM383.965 40.4637H368.97V60.5522H384.157C389.539 60.5522 394.057 56.131 394.057 50.6521C393.961 44.9814 389.443 40.4637 383.965 40.4637Z"
          fill="white"
        />
        <path
          d="M417.985 73.4316V2.5946H468.831V15.5699H430.865V29.3146H456.432V42.098H430.865V60.4558H468.831V73.4316H417.985Z"
          fill="white"
        />
        <path
          d="M517.846 73.4316V66.5112L508.715 47.4805H492.76V73.4316H479.88V2.5946C479.88 2.5946 496.028 2.5946 508.331 2.5946C520.633 2.5946 530.725 12.5906 530.725 24.8934C530.725 32.6786 526.689 39.5989 520.633 43.6359L530.149 61.9936C530.437 62.859 530.725 63.8203 530.725 64.781V73.4316H517.846ZM508.138 15.1857C502.564 15.1857 492.568 15.1857 492.568 15.1857V34.9854C492.568 34.9854 502.564 34.9854 508.138 34.9854C513.521 34.9854 517.942 30.5642 517.942 25.1817C517.942 19.6069 513.521 15.1857 508.138 15.1857Z"
          fill="white"
        />
        <path
          d="M578.299 73.4315V68.5296C576.954 72.1819 569.937 74.8734 565.228 74.8734C551.194 74.8734 539.853 63.5314 539.853 49.4989V26.5271C539.853 12.4941 551.194 1.15259 565.228 1.15259C578.396 1.15259 589.257 11.1486 590.506 24.028L577.818 26.623C577.818 19.7027 572.147 14.1284 565.132 14.0319C558.403 14.0319 552.732 19.6068 552.732 26.5271V49.4989C552.732 56.2269 558.403 61.8976 565.228 61.8976C572.147 61.8976 578.203 57.0917 578.203 49.4989V48.2493H570.609V36.7155H590.698V73.4315H578.299Z"
          fill="white"
        />
        <path
          d="M622.317 74.777C607.034 74.6811 598.385 65.6463 596.943 54.1125L609.822 52.1899L610.11 54.3043C610.495 58.6296 614.436 61.8976 618.856 61.8976H625.392C630.295 61.8976 634.619 57.2841 634.619 52.3823C634.619 47.5764 631.544 43.2511 625.777 41.9056L615.204 39.4065C606.938 37.4844 600.787 29.5069 600.787 20.4716C600.787 9.80314 609.341 1.24854 619.915 1.24854H623.47C633.658 1.24854 642.021 9.22606 642.597 19.4144L630.006 21.2406C630.006 21.2406 629.525 18.6455 629.429 18.2613C628.757 15.8581 626.354 13.936 623.663 13.936H618.953C615.878 13.936 613.185 16.5311 613.185 19.7027V21.337C613.185 24.4127 615.493 26.7195 618.28 27.392L628.18 29.6987C632.12 30.66 635.87 32.5826 639.041 34.9853C643.942 38.8299 647.307 44.5966 647.115 51.9975C646.922 64.3968 637.503 74.777 622.317 74.777Z"
          fill="white"
        />
        <path
          d="M693.247 73.4315V68.5296C691.903 72.1819 684.886 74.8734 680.176 74.8734C666.143 74.8734 654.801 63.5314 654.801 49.4989V26.5271C654.801 12.4941 666.143 1.15259 680.176 1.15259C693.343 1.15259 704.205 11.1486 705.454 24.028L692.767 26.623C692.767 19.7027 687.096 14.1284 680.08 14.0319C673.352 14.0319 667.681 19.6068 667.681 26.5271V49.4989C667.681 56.2269 673.352 61.8976 680.176 61.8976C687.096 61.8976 693.152 57.0917 693.152 49.4989V48.2493H685.558V36.7155H705.646V73.4315H693.247Z"
          fill="white"
        />
        <path
          d="M752.163 73.4316V67.2802L750.145 60.1675H729.576L727.654 67.2802V73.4316H714.294V68.145C714.294 67.2802 714.391 66.5112 714.774 65.7423L733.613 2.5946H745.628L764.562 65.55C764.755 66.3189 765.14 67.1842 765.14 68.0491V73.4316H752.163ZM739.669 22.7785L732.941 47.7688H746.685L739.669 22.7785Z"
          fill="white"
        />
        <path
          d="M795.125 15.5699V73.4316H781.957V15.5699H763.215V2.5946H814.06V15.5699H795.125Z"
          fill="white"
        />
        <path
          d="M848.563 73.4316V67.2802L846.545 60.1675H825.976L824.053 67.2802V73.4316H810.693V68.145C810.693 67.2802 810.79 66.5112 811.174 65.7423L830.013 2.5946H842.027L860.962 65.55C861.155 66.3189 861.539 67.1842 861.539 68.0491V73.4316H848.563ZM836.068 22.7785L829.34 47.7688H843.085L836.068 22.7785Z"
          fill="white"
        />
        <path
          d="M909.884 73.4316L884.028 27.4885V73.4316H871.63V2.5946H884.028L909.403 47.5764V2.5946H921.994V73.4316H909.884V73.4316Z"
          fill="white"
        />
        <path
          d="M1016.66 74.6798H963.197L963.327 73.3132C965.352 51.8917 974.913 47.0955 992.268 38.3898L993.804 37.6187C998.915 35.1065 1001.19 31.7168 1001.19 26.6231C1001.19 20.4218 996.145 15.3769 989.944 15.3769C983.916 15.3769 980.031 20.2096 978.853 24.999L978.093 28.0468L963.328 25.5492L963.42 24.4084C964.527 10.7216 976.178 0.00012207 989.944 0.00012207C1004.62 0.00012207 1016.57 11.9433 1016.57 26.6231C1016.57 37.7353 1010.79 45.727 998.373 51.7747C997.596 52.1453 996.709 52.5326 995.758 52.9481C992.186 54.5059 987.5 56.5494 985.507 59.304H1016.66V74.6798ZM965.954 72.1827H1014.17V61.8011H981.845L982.305 60.2063C983.662 55.4989 990.076 52.7013 994.759 50.6588C995.682 50.2569 996.542 49.8814 997.287 49.5253C1008.73 43.9488 1014.07 36.6701 1014.07 26.6231C1014.07 13.3202 1003.25 2.49723 989.944 2.49723C977.854 2.49723 967.564 11.6219 966.028 23.4734L976.23 25.1995L976.43 24.3986C978.122 17.5106 983.554 12.8797 989.944 12.8797C997.522 12.8797 1003.69 19.045 1003.69 26.6231C1003.69 32.6255 1000.82 36.9539 994.916 39.8549L993.387 40.6216C976.686 48.9996 968.11 53.3022 965.954 72.1827Z"
          fill="white"
        />
        <path
          d="M1070.39 74.6798H1055.01V62.5698H1026.66V48.2748L1056.05 1.34619H1070.39V47.2896H1080V62.5698H1070.39V74.6798ZM1057.51 72.1827H1067.89V60.0727H1077.5V49.7867H1067.89V3.8433H1057.43L1029.16 48.9927V60.0727H1057.51V72.1827ZM1057.51 49.7867H1039.57L1057.51 21.6737V49.7867ZM1044.12 47.2896H1055.01V30.2273L1044.12 47.2896Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1171_444">
          <rect width="1080" height="74.8735" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
