import { clsx } from "clsx"

interface WysiwygProps {
  content: string
  className?: string
}

export const Wysiwyg = ({ content, className }: WysiwygProps) => {
  return (
    <div className={clsx("wysiwyg", className)} dangerouslySetInnerHTML={{ __html: content }}></div>
  )
}
