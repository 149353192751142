import type { GetStaticProps } from "next"

import { GET_HOME_FIELDS } from "@graphql/graphql-queries"
import { getAcfGlobalFields, getPageFields } from "@utils/graphql"
import invariant from "tiny-invariant"

import { Landing } from "@components/landing"
import { Layout } from "@components/layout"
import { MetadataProps } from "@components/shared/Metadata"
import { GQLGlobalFields } from "@models/common"
import { GQLHomeFields } from "@models/pages"

interface IndexProps {
  globalFields: GQLGlobalFields
  home: GQLHomeFields
}

export const getStaticProps: GetStaticProps<IndexProps> = async context => {
  // ensure necessary env vars are defined
  invariant(process.env.WP_PAGEID_HOME, "WP_PAGEID_HOME is undefined")
  invariant(process.env.NEXT_PAGE_REVALIDATE, "NEXT_PAGE_REVALIDATE is undefined")

  // fetch data from wp
  const [globalFields, home] = await Promise.all([
    getAcfGlobalFields(context.locale),
    getPageFields<GQLHomeFields>(context, GET_HOME_FIELDS, process.env.WP_PAGEID_HOME),
  ])

  // ensure necessary data exist
  invariant(globalFields, "global fields is undefined")
  invariant(home, "home is undefined")

  return {
    props: { globalFields, home },
    revalidate: +process.env.NEXT_PAGE_REVALIDATE,
  }
}

export default function Index({ globalFields, home }: IndexProps) {
  const { title, featuredImage, seo, acfHome } = home.page
  const { generalSettings, acfGlobalFields } = globalFields

  const seoParam: MetadataProps = {
    title,
    slug: "/",
    metaTitle: seo?.title ?? generalSettings.title,
    metaDescription: seo?.metaDesc ?? generalSettings.description,
    twitterTitle: seo?.twitterTitle ?? generalSettings.title,
    twitterDescription: seo?.twitterDescription ?? generalSettings.description,
    opengraphTitle: seo?.opengraphTitle ?? generalSettings.title,
    opengraphDescription: seo?.opengraphDescription ?? generalSettings.description,
    image: featuredImage?.node.mediaItemUrl,
    locales: acfGlobalFields.acfGlobal.locales,
  }

  return (
    <Layout {...globalFields} seo={seoParam} isHomePage>
      <Landing
        title={acfHome.title}
        description={acfHome.description}
        backgroundType={acfHome.bgType}
        videoId={acfHome.video}
        videoThumbnail={acfHome.videoThumbnail}
        bgImage={acfHome.image}
        menuBtnLabel={acfHome.viewMenuLabel}
      />
    </Layout>
  )
}

Index.animateKey = "Index"
