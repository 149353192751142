import { patternProps } from "./"

export const MoodDistrictPattern = ({ className }: patternProps) => {
  return (
    <svg
      className={className}
      width="1002"
      height="838"
      viewBox="0 0 1002 838"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M106.432 467.942C9.90446 520.913 54.5484 610.641 6.36216 653.342C-17.5484 674.53 40.0141 682.476 148.94 716.024C149.826 847.57 480.598 972.233 556.765 512.496"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M108.473 467.52C16.8737 521.105 60.7702 606.855 16.4502 647.2C-5.76602 667.283 50.3668 674.361 155.791 705.873C157.618 771.185 246.356 834.62 341.109 817.001C396.783 806.571 453.637 769.247 497.31 690.84C521.985 646.541 541.608 587.512 556.976 511.826"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M110.514 467.097C23.8426 521.297 67.1378 603.224 26.5379 641.058C6.08942 660.114 60.7191 666.245 162.641 695.722C165.831 757.389 252.193 817.191 344.128 799.971C399.097 789.521 454.262 753.655 497.174 680.146C521.16 639.059 539.619 583.04 557.187 511.155"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M112.554 466.675C30.8118 521.489 73.4422 599.517 36.6258 634.916C17.9139 652.908 71.0718 658.129 169.491 685.57C174.044 743.592 258.064 799.943 347.147 782.941C401.433 772.58 454.875 738.024 497.038 669.452C520.342 631.551 537.63 578.567 557.398 510.485"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M114.595 466.252C37.781 521.68 79.7553 595.812 46.7137 628.775C29.7436 645.704 81.4243 650.014 176.342 675.419C182.257 729.796 263.911 782.565 350.166 765.911C403.754 755.563 455.479 722.345 496.902 658.759C519.536 624.013 535.642 574.095 557.609 509.814"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M116.636 465.831C44.75 521.873 86.0799 592.108 56.8016 622.633C41.5801 638.503 91.7769 641.899 183.192 665.269C190.471 716 269.757 765.183 353.185 748.881C406.076 738.545 456.076 706.61 496.766 648.066C518.747 616.438 533.653 569.624 557.82 509.144"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M118.676 465.408C51.7191 522.065 92.4196 588.404 66.8895 616.492C53.4258 631.304 102.129 633.784 190.042 655.118C198.684 702.204 275.603 747.796 356.204 731.851C408.397 721.525 456.674 690.806 496.629 637.372C517.981 608.818 531.664 565.152 558.031 508.474"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M120.717 464.987C58.6883 522.257 98.78 584.698 76.9774 610.351C65.2842 624.108 112.482 625.669 196.893 644.967C206.897 688.408 281.447 730.404 359.223 714.821C410.718 704.504 457.283 674.923 496.493 626.679C517.248 601.142 529.676 560.681 558.242 507.804"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M122.758 464.564C65.6573 522.448 105.169 580.985 87.0652 604.208C77.1607 616.914 122.834 617.553 203.743 634.815C215.11 674.611 287.291 713.006 362.242 697.79C413.039 687.478 457.924 658.946 496.357 615.985C516.563 593.398 527.687 556.208 558.453 507.132"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M124.799 464.142C72.6265 522.641 111.6 577.257 97.1531 598.067C89.0636 609.72 133.187 609.438 210.594 624.664C223.323 660.815 293.133 695.602 365.262 680.761C415.359 670.452 458.628 642.87 496.221 605.292C515.947 585.573 525.698 551.737 558.665 506.462"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M126.839 463.72C79.5955 522.832 118.09 573.494 107.241 591.925C101.005 602.519 143.54 601.323 217.444 614.513C231.537 647.019 298.975 678.193 368.281 663.73C417.679 653.422 465.958 632.836 496.085 594.598C518.213 581.469 523.71 547.265 558.876 505.792"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M128.88 463.298C86.5646 523.025 124.666 569.654 117.329 585.784C113.002 595.296 153.892 593.208 224.294 604.363C239.75 633.223 304.816 660.777 371.3 646.701C419.999 636.389 467.254 617.639 495.949 583.905C517.753 574.216 521.721 542.793 559.087 505.122"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M130.92 462.875C93.5337 523.217 131.352 565.638 127.417 579.642C125.066 588.006 164.245 585.093 231.145 594.211C247.963 619.426 310.655 643.355 374.319 629.67C422.318 619.353 468.549 602.442 495.812 573.211C517.292 566.963 519.732 538.321 559.297 504.451"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M132.961 462.453C100.503 523.408 135.43 561.422 137.505 573.501C135.621 580.315 174.597 576.977 237.995 584.06C256.176 605.63 316.493 625.926 377.338 612.64C424.636 602.312 469.845 587.245 495.676 562.517C516.832 559.71 517.744 533.849 559.509 503.781"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M135.002 462.031C107.472 523.6 141.652 557.636 147.592 567.359C147.403 573.067 184.95 568.862 244.846 573.909C264.39 591.834 322.33 608.49 380.357 595.61C426.953 585.267 471.141 572.048 495.54 551.824C516.372 552.456 515.755 529.377 559.72 503.11"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M137.042 461.609C114.441 523.792 147.873 553.85 157.68 561.218C159.186 565.82 195.302 560.747 251.696 563.758C272.603 578.038 328.165 591.048 383.376 578.58C429.269 568.217 472.437 556.852 495.404 541.13C515.911 545.203 513.766 524.906 559.931 502.44"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M139.083 461.186C121.41 523.984 154.095 550.064 167.768 555.076C170.968 558.573 205.655 552.631 258.546 553.607C280.816 564.241 333.998 573.597 386.395 561.55C431.584 551.16 473.733 541.655 495.268 530.437C515.451 537.95 511.778 520.433 560.142 501.769"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M141.124 460.764C128.379 524.176 160.925 543.653 177.856 548.934C183.058 550.557 216.007 544.516 265.397 543.456C289.029 550.445 339.83 556.139 389.414 544.52C433.897 534.096 475.028 526.458 495.132 519.743C514.991 530.697 509.789 515.962 560.353 501.099"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M143.165 460.342C135.349 524.368 166.651 540.584 187.944 542.793C194.622 543.485 226.36 536.401 272.247 533.305C297.243 536.649 345.659 538.672 392.433 527.49C426.255 519.47 460.495 513.314 494.996 509.05C514.531 523.444 507.801 511.49 560.564 500.428"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M145.205 459.919C142.318 524.56 172.688 537.404 198.032 536.651C206.313 536.405 236.712 528.286 279.098 523.153C305.456 522.852 351.486 521.197 395.452 510.46C438.516 499.942 477.62 496.064 494.86 498.356C514.07 516.191 505.812 507.018 560.775 499.758"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M147.246 459.497C149.287 524.752 178.866 534.078 208.12 530.509C218.066 529.296 247.065 520.17 285.948 513.002C313.669 509.056 357.311 503.713 398.471 493.429C440.821 482.849 478.915 480.867 494.724 487.663C513.61 508.937 503.823 502.546 560.986 499.087"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M149.287 459.075C156.256 524.943 185.106 530.64 218.208 524.368C229.848 522.162 257.417 512.055 292.798 502.851C321.882 495.26 363.132 486.22 401.49 476.399C443.122 465.74 481.805 463.904 494.587 476.969C516.212 499.071 501.835 498.074 561.197 498.417"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M151.327 458.653C163.225 525.136 191.371 527.127 228.296 518.226C241.643 515.009 267.77 503.94 299.649 492.7C330.096 481.464 368.951 468.717 404.51 459.369C445.418 448.615 482.41 449.779 494.451 466.275C514.233 493.375 499.846 493.602 561.408 497.747"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M153.368 458.23C170.194 525.327 197.647 523.562 238.384 512.085C270.327 503.085 345.538 459.113 407.529 442.339C447.707 431.467 482.803 435.276 494.315 455.582C512.229 487.178 497.858 489.13 561.619 497.076"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M153.566 457.409C169.969 518.901 197.032 518.712 238.884 502.143C270.557 489.604 346.678 452.08 407.454 435.785C448.388 424.964 481.081 426.74 496.428 449.113C517.96 480.5 499.314 489.473 563.494 496.593"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M153.765 456.587C169.744 512.475 196.326 514.044 239.384 492.201C270.748 476.292 347.765 444.847 407.38 429.232C449.033 418.321 479.181 418.197 498.541 442.643C523.323 473.936 500.77 489.816 565.369 496.11"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M153.963 455.765C169.518 506.049 195.593 509.546 239.885 482.26C270.942 463.127 348.884 437.733 407.305 422.678C449.698 411.753 477.144 409.692 500.655 436.174C528.451 467.484 502.226 490.158 567.244 495.627"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M154.161 454.943C169.293 499.623 194.881 505.197 240.386 472.318C271.164 450.08 350.007 430.63 407.231 416.124C450.366 405.19 475.003 401.246 502.768 429.705C533.426 461.131 503.682 490.501 569.119 495.144"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M154.359 454.121C169.068 493.197 194.219 500.968 240.886 462.376C271.425 437.122 351.133 423.539 407.156 409.57C451.034 398.63 472.782 392.865 504.881 423.236C538.303 454.859 505.138 490.844 570.994 494.661"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M154.558 453.299C168.843 486.77 193.622 496.832 241.387 452.434C271.732 424.228 352.262 416.458 407.082 403.016C451.705 392.075 470.499 384.549 506.994 416.766C543.116 448.655 506.594 491.186 572.869 494.177"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M154.756 452.477C168.617 480.344 193.096 492.768 241.887 442.493C272.082 411.379 353.394 409.387 407.008 396.463C452.377 385.525 468.169 376.295 509.107 410.297C547.887 442.506 508.05 491.529 574.744 493.695"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M154.955 451.655C168.392 473.918 192.64 488.756 242.388 432.551C272.474 398.559 354.529 402.326 406.933 389.909C453.051 378.981 465.801 368.098 511.22 403.828C552.63 436.404 509.506 491.871 576.62 493.211"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M155.153 450.833C168.167 467.492 192.25 484.782 242.888 422.609C272.903 385.759 355.666 395.275 406.859 383.355C453.726 372.442 463.403 359.952 513.333 397.359C557.355 430.339 510.962 492.214 578.494 492.728"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M155.351 450.011C167.942 461.066 191.921 480.833 243.389 412.667C273.363 372.969 356.806 388.232 406.784 376.801C454.403 365.91 460.983 351.853 515.446 390.889C562.069 424.305 512.418 492.556 580.369 492.245"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M155.549 449.189C167.716 454.64 191.645 476.902 243.889 402.726C273.852 360.186 357.948 381.199 406.71 370.248C455.082 359.384 458.544 343.796 517.559 384.42C566.776 418.299 513.875 492.899 582.244 491.763"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M155.748 448.367C167.491 448.213 191.418 472.98 244.39 392.784C274.365 347.403 359.092 374.172 406.635 363.693C455.762 352.866 456.091 335.775 519.672 377.95C571.479 412.315 515.331 493.241 584.119 491.279"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M155.946 447.545C167.266 441.787 191.234 469.066 244.89 382.842C274.898 334.622 360.237 367.155 406.561 357.14C456.445 346.355 453.626 327.788 521.786 371.481C576.18 406.35 516.787 493.584 585.994 490.796"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M156.144 446.723C167.041 435.361 191.086 465.154 245.391 372.901C275.449 321.838 361.384 360.144 406.487 350.586C457.129 339.853 451.151 319.831 523.899 365.012C580.881 400.401 518.243 493.926 587.87 490.313"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M156.343 445.901C166.815 428.934 190.97 461.242 245.892 362.959C276.015 309.051 362.533 353.141 406.412 344.032C457.815 333.361 448.669 311.901 526.012 358.542C585.583 394.467 519.699 494.269 589.745 489.829"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M156.541 445.079C166.59 422.509 190.883 457.33 246.392 353.017C276.594 296.261 363.683 346.145 406.338 337.478C458.503 326.879 446.181 303.994 528.125 352.073C590.286 388.545 521.155 494.612 591.62 489.347"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M156.739 444.257C166.365 416.082 190.82 453.414 246.893 343.075C277.185 283.467 364.833 339.156 406.263 330.925C459.193 320.408 443.688 296.109 530.238 345.604C594.99 382.633 522.611 494.954 593.495 488.863"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M156.937 443.435C166.14 409.656 190.779 449.496 247.393 333.134C277.785 270.668 365.985 332.173 406.189 324.371C459.885 313.95 441.192 288.243 532.351 339.135C599.697 376.731 524.067 495.297 595.37 488.38"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M157.136 442.613C165.915 403.23 190.756 445.574 247.894 323.192C278.393 257.866 367.138 325.197 406.114 317.817C460.579 307.505 438.692 280.395 534.464 332.666C604.406 370.838 525.523 495.64 597.245 487.898"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M157.334 441.791C165.689 396.804 190.749 441.648 248.394 313.25C279.009 245.059 368.291 318.226 406.04 311.263C461.276 301.075 436.189 272.562 536.577 326.196C609.117 364.951 526.98 495.982 599.12 487.414"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M157.532 440.969C165.464 390.378 190.757 437.718 248.895 303.309C279.631 232.249 369.445 311.262 405.966 304.71C461.974 294.661 433.685 264.744 538.69 319.727C613.83 359.072 528.436 496.325 600.995 486.931"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M157.731 440.147C165.239 383.952 190.778 433.783 249.395 293.367C280.259 219.435 370.598 304.303 405.891 298.156C462.675 288.266 431.179 256.938 540.804 313.258C618.546 353.198 529.892 496.667 602.87 486.448"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M157.929 439.325C165.014 377.525 190.81 429.845 249.896 283.425C280.891 206.617 371.752 297.349 405.817 291.602C463.379 281.89 428.671 249.144 542.917 306.788C623.264 347.329 531.348 497.01 604.745 485.965"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M158.049 438.065C162.899 374.75 186.05 418.016 246.8 277.778C279.183 203.025 368.982 292.847 403.047 287.1C460.61 277.388 427.584 249.944 540.026 300.966C619.223 336.902 543.093 483.957 604.964 485.71"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M158.17 436.804C160.785 371.975 181.401 406.166 243.705 272.131C277.515 199.395 366.213 288.345 400.277 282.598C457.84 272.887 426.53 250.748 537.136 295.144C615.206 326.48 554.837 470.905 605.183 485.455"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M158.29 435.543C158.67 369.199 176.872 394.3 240.61 266.484C275.892 195.73 363.443 283.843 397.508 278.096C455.07 268.384 425.512 251.55 534.246 289.321C611.217 316.057 566.582 457.852 605.403 485.199"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M158.411 434.283C156.556 366.424 172.474 382.427 237.514 260.837C274.319 192.032 360.673 279.341 394.738 273.594C452.301 263.883 424.535 252.346 531.356 283.498C607.257 305.633 578.327 444.8 605.622 484.945"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M158.531 433.022C154.442 363.648 168.217 370.554 234.419 255.19C272.801 188.304 357.904 274.839 391.968 269.092C449.531 259.381 423.6 253.127 528.466 277.676C603.328 295.201 590.072 431.747 605.841 484.69"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M158.651 431.762C152.327 360.873 164.109 358.692 231.323 249.543C271.345 184.552 355.134 270.337 389.199 264.59C446.761 254.879 422.709 253.884 525.576 271.853C599.431 284.755 601.816 418.695 606.061 484.434"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M158.772 430.501C150.213 358.098 160.159 346.852 228.228 243.896C269.955 180.782 352.364 265.835 386.429 260.088C443.991 250.377 421.861 254.608 522.686 266.031C595.566 274.288 613.561 405.642 606.28 484.179"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M158.892 429.24C148.099 355.322 156.372 335.047 225.133 238.248C268.639 177.001 349.595 261.333 383.659 255.586C441.222 245.874 421.052 255.288 519.796 260.208C591.731 263.792 625.306 392.589 606.499 483.924"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M159.013 427.98C145.984 352.547 152.753 323.291 222.037 232.602C267.402 173.222 346.825 256.831 380.889 251.084C438.452 241.373 420.276 255.916 516.906 254.386C587.92 253.261 637.051 379.537 606.719 483.669"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M159.133 426.72C143.87 349.772 149.301 311.598 218.942 226.955C266.25 169.455 344.055 252.33 378.12 246.583C435.682 236.871 419.524 256.483 514.016 248.564C584.127 242.688 648.795 366.485 606.938 483.414"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M159.254 425.459C141.756 346.996 146.017 299.983 215.846 221.307C265.188 165.715 341.285 247.827 375.35 242.08C432.913 232.369 418.784 256.982 511.125 242.741C580.343 232.066 660.54 353.432 607.157 483.159"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M159.374 424.198C139.641 344.22 142.895 288.462 212.751 215.66C264.221 162.02 338.516 243.326 372.58 237.579C430.143 227.867 418.041 257.413 508.235 236.918C576.558 221.394 672.285 340.379 607.377 482.904"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M159.494 422.938C137.527 341.445 139.925 277.052 209.656 210.013C263.352 158.39 335.746 238.824 369.811 233.077C427.373 223.365 417.282 257.777 505.345 231.096C572.76 210.671 684.03 327.327 607.596 482.649"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M159.615 421.677C135.413 338.67 137.094 265.767 206.56 204.366C262.583 154.848 332.977 234.322 367.041 228.575C424.604 218.863 416.493 258.08 502.455 225.273C568.939 199.9 695.775 314.274 607.815 482.394"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M159.735 420.417C133.298 335.894 134.386 254.621 203.465 198.719C261.913 151.42 330.207 229.82 364.271 224.073C421.834 214.361 415.663 258.331 499.565 219.451C565.086 189.088 707.519 301.222 608.035 482.138"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M159.856 419.156C131.184 333.119 131.778 243.628 200.369 193.072C261.339 148.134 327.437 225.318 361.502 219.571C419.064 209.86 414.784 258.544 496.675 213.628C561.193 178.242 719.264 288.17 608.254 481.884"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M159.976 417.896C129.07 330.343 129.243 232.799 197.274 187.425C260.853 145.02 324.667 220.816 358.732 215.069C416.294 205.357 413.85 258.728 493.785 207.806C557.258 167.37 731.009 275.117 608.473 481.628"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M160.097 416.635C126.955 327.568 126.75 222.142 194.179 181.778C260.445 142.109 321.898 216.314 355.962 210.567C413.525 200.856 412.861 258.898 490.895 201.983C553.277 156.484 742.754 262.065 608.693 481.373"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M160.217 415.375C124.841 324.793 124.265 211.664 191.083 176.131C260.096 139.431 319.128 211.812 353.193 206.065C410.755 196.354 411.818 259.065 488.004 196.161C549.253 145.591 754.498 249.012 608.912 481.118"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M160.337 414.114C122.727 322.017 121.75 201.364 187.988 170.484C259.784 137.012 316.358 207.31 350.423 201.563C407.985 191.852 410.725 259.236 485.114 190.338C545.189 134.698 766.243 235.959 609.131 480.863"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M160.458 412.854C120.612 319.242 119.163 191.242 184.892 164.837C259.478 134.874 313.589 202.808 347.653 197.061C405.216 187.35 409.587 259.421 482.224 184.516C541.088 123.814 777.988 222.907 609.35 480.608"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M160.578 411.593C118.498 316.467 116.464 181.288 181.797 159.19C259.144 133.028 310.819 198.306 344.883 192.559C402.446 182.848 408.411 259.624 479.334 178.693C536.956 112.94 789.733 209.855 609.57 480.353"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M160.699 410.333C116.383 313.691 113.614 171.49 178.702 153.543C258.742 131.472 308.049 193.804 342.114 188.057C399.676 178.346 407.201 259.848 476.444 172.871C532.798 102.082 801.477 196.802 609.789 480.098"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M129.05 243.829C129.05 243.829 127.2 172.988 169.137 151.28C191.337 139.788 242.765 144.132 286.315 167.448"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M128.498 243.004C128.158 242.517 126.17 226.678 129.622 207.449C133.629 185.128 145.597 157.356 169.356 147.558C181.473 142.561 200.04 139.865 221.682 143.159C244.212 146.589 264.593 155.528 287.291 167.913"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M127.946 242.179C127.267 241.205 123.682 225.158 126.686 205.542C130.269 182.141 144.364 151.963 169.574 143.836C187.071 138.084 205.732 136.777 223.864 140.035C247.8 144.369 264.722 155.307 288.267 168.379"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M127.394 241.353C126.375 239.893 121.179 223.63 123.75 203.635C126.898 179.145 143.187 146.618 169.793 140.115C184.13 136.611 203.096 132.15 226.047 136.91C251.391 142.167 264.852 155.086 289.244 168.844"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M126.842 240.528C125.484 238.582 118.662 222.098 120.813 201.728C123.515 176.143 142.049 141.322 170.011 136.393C185.494 133.664 204.624 128.32 228.23 133.785C254.983 139.98 264.981 154.864 290.22 169.31"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M126.29 239.703C124.593 237.27 116.133 220.561 117.877 199.821C120.121 173.135 140.938 136.073 170.23 132.672C186.873 130.739 206.151 124.505 230.413 130.661C258.575 137.807 265.11 154.644 291.196 169.775"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M125.739 238.878C123.701 235.958 113.593 219.022 114.941 197.914C116.715 170.124 139.842 130.866 170.448 128.95C188.261 127.835 207.677 120.703 232.596 127.537C262.166 135.646 265.24 154.423 292.173 170.241"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M125.187 238.053C122.81 234.646 111.042 217.48 112.005 196.008C113.299 167.109 138.754 125.695 170.667 125.229C189.654 124.951 209.201 116.911 234.778 124.412C265.755 133.496 265.369 154.201 293.149 170.706"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M124.635 237.228C121.918 233.334 109.762 215.933 109.068 194.101C108.114 164.096 137.667 120.555 170.885 121.507C191.047 122.085 210.722 113.128 236.961 121.287C269.341 131.357 265.498 153.98 294.125 171.172"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M124.083 236.402C121.027 232.023 107.257 214.364 106.132 192.194C104.555 161.111 136.579 115.441 171.104 117.786C192.439 119.235 212.242 109.353 239.144 118.163C272.926 129.226 265.628 153.759 295.101 171.637"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M123.531 235.577C120.136 230.711 104.729 212.795 103.196 190.287C101.005 158.127 135.486 110.348 171.322 114.064C193.829 116.399 213.759 105.584 241.327 115.038C276.507 127.104 265.757 153.538 296.078 172.103"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M122.98 234.752C119.244 229.399 102.18 211.225 100.259 188.38C97.4646 155.142 134.388 105.272 171.54 110.343C195.215 113.574 215.275 101.821 243.51 111.914C280.086 124.988 265.886 153.317 297.054 172.568"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M122.428 233.927C118.353 228.087 98.1685 209.755 97.3231 186.474C96.0718 152.014 133.284 100.211 171.759 106.621C196.597 110.76 216.788 98.0628 245.692 108.789C283.662 122.88 266.016 153.096 298.03 173.034"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M121.876 233.102C117.461 226.775 95.5746 208.209 94.3868 184.566C92.5996 148.994 132.173 95.1609 171.977 102.9C197.975 107.954 218.299 94.3084 247.875 105.664C287.236 120.777 266.145 152.875 299.006 173.499"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M121.324 232.276C116.57 225.464 92.9758 206.664 91.4505 182.66C89.1197 145.975 131.056 90.1207 172.196 99.1782C199.349 105.156 219.809 90.558 250.058 102.54C290.806 118.681 266.274 152.654 299.983 173.965"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M120.772 231.452C115.679 224.152 90.3724 205.121 88.5143 180.753C85.6323 142.958 129.932 85.0885 172.414 95.4569C200.718 102.365 221.316 86.8111 252.241 99.4156C294.374 116.589 266.404 152.433 300.959 174.43"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M120.221 230.626C114.787 222.84 87.765 203.577 85.5781 178.846C82.1378 139.941 128.802 80.0623 172.633 91.7353C202.083 99.5786 222.822 83.0666 254.424 96.291C297.94 114.502 266.533 152.212 301.935 174.896"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M119.669 229.801C113.896 221.528 85.1537 202.034 82.6418 176.939C78.6365 136.926 127.666 75.0412 172.851 88.0137C203.445 96.797 224.327 79.3245 256.606 93.1663C301.503 112.418 266.662 151.991 302.912 175.361"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M119.117 228.976C113.005 220.216 82.5392 200.492 79.7055 175.032C75.1288 133.912 126.525 70.0244 173.07 84.2921C204.802 94.0192 225.83 75.5847 258.789 90.0416C305.064 110.339 266.792 151.769 303.888 175.826"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M118.565 228.151C112.113 218.905 79.9215 198.952 76.7692 173.126C71.6151 130.9 125.378 65.0113 173.288 80.5707C206.157 91.2451 227.331 71.847 260.972 86.9171C308.622 108.263 266.921 151.548 304.864 176.292"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M118.013 227.325C111.222 217.593 77.3012 197.412 73.833 171.219C68.0958 127.889 124.227 60.0007 173.507 76.849C207.508 88.4736 228.831 68.1106 263.155 83.7923C312.178 106.19 267.051 151.327 305.841 176.757"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M117.462 226.5C110.33 216.281 74.6782 195.873 70.8966 169.312C64.571 124.88 123.071 54.9926 173.725 73.1274C208.855 85.7046 230.33 64.376 265.337 80.6677C315.732 104.12 267.18 151.106 306.817 177.223"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M116.91 225.675C109.439 214.969 72.0528 194.335 67.9603 167.405C61.041 121.873 121.911 49.9866 173.944 69.4061C210.201 82.938 231.827 60.6429 267.52 77.5433C319.284 102.053 267.309 150.885 307.793 177.688"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M116.358 224.85C108.548 213.657 69.4255 192.798 65.0241 165.498C57.5063 118.867 120.748 44.982 174.162 65.6845C211.543 80.1729 233.324 56.9108 269.703 74.4187C322.835 99.9886 267.439 150.664 308.769 178.154"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M115.806 224.025C107.656 212.345 66.7961 191.262 62.0879 163.591C53.967 115.863 119.581 39.9785 174.38 61.9628C212.883 77.4093 234.819 53.1797 271.886 71.2939C326.383 97.9262 267.568 150.443 309.746 178.619"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M115.254 223.2C106.765 211.034 64.165 189.728 59.1515 161.684C50.4233 112.862 118.411 34.9765 174.599 58.2415C214.221 74.6475 236.313 49.4499 274.069 68.1695C329.93 95.8662 267.697 150.222 310.722 179.085"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M114.702 222.375C105.874 209.722 61.5322 188.194 56.2152 159.778C46.8756 109.861 117.238 29.975 174.817 54.52C215.557 71.8865 237.806 45.7209 276.251 65.045C333.475 93.808 267.827 150.001 311.698 179.55"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M114.151 221.549C104.982 208.41 58.8979 186.661 53.279 157.871C43.3239 106.863 116.062 24.9742 175.036 50.7983C216.891 69.1265 239.299 41.9925 278.434 61.9203C337.018 91.7516 267.956 149.779 312.674 180.016"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M113.599 220.724C104.091 207.098 56.2623 185.129 50.3427 155.964C39.7686 103.867 114.884 19.9743 175.254 47.0771C218.224 66.3677 240.791 38.2652 280.617 58.7959C340.56 89.6971 268.085 149.559 313.651 180.481"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M113.047 219.899C103.199 205.786 53.6255 183.598 47.4065 154.057C36.2099 100.872 113.703 14.9745 175.473 43.3555C219.554 63.6093 242.281 34.5382 282.8 55.6712C344.101 87.6439 268.215 149.337 314.627 180.947"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M112.495 219.074C102.308 204.475 50.9875 182.069 44.4702 152.15C32.6478 97.8784 112.52 9.97512 175.691 39.634C220.883 60.8517 243.771 30.8119 284.982 52.5467C347.64 85.5924 268.344 149.116 315.603 181.412"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M111.944 218.249C101.417 203.163 48.3485 180.54 41.534 150.243C29.0826 94.8869 111.335 4.97604 175.91 35.9125C222.211 58.0947 245.261 27.0862 287.165 49.4222C351.178 83.5422 268.473 148.895 316.58 181.878"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M111.392 217.423C100.525 201.851 45.7084 179.011 38.5976 148.337C25.5144 91.8969 110.149 -0.0228872 176.128 32.1909C223.538 55.3381 246.749 23.3609 289.348 46.2974C354.715 81.4931 268.603 148.674 317.556 182.343"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M110.84 216.598C99.6339 200.539 43.0674 177.484 35.6613 146.43C21.9433 88.9087 108.96 -5.02157 176.347 28.4694C224.863 52.582 248.237 19.6362 291.531 43.173C358.251 79.4456 268.732 148.453 318.532 182.809"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M110.288 215.773C98.7425 199.228 40.4256 175.958 32.7251 144.523C18.3697 85.9221 107.77 -10.0201 176.565 24.748C226.187 49.8264 249.725 15.9118 293.714 40.0484C361.785 77.399 268.861 148.232 319.508 183.274"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M109.736 214.948C97.8511 197.916 37.783 174.432 29.7888 142.616C14.7935 82.9369 106.579 -15.0187 176.783 21.0265C227.51 47.0709 251.211 12.1879 295.896 36.9239C365.319 75.3535 268.991 148.011 320.485 183.74"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M109.184 214.123C96.9596 196.604 35.1397 172.907 26.8524 140.709C11.2148 79.9532 105.386 -20.0174 177.002 17.3048C228.832 44.3157 252.66 8.53038 298.079 33.7992C368.793 73.4122 269.12 147.79 321.461 184.205"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M108.633 213.298C96.0684 195.292 32.4511 171.395 23.9163 138.802C7.54908 76.9935 104.152 -24.9405 177.22 13.5834C230.124 41.6154 254.164 4.77413 300.262 30.6747C372.353 71.3174 269.249 147.569 322.437 184.671"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M108.081 212.472C95.1771 193.98 29.8514 169.86 20.98 136.895C4.051 73.9901 102.996 -30.0146 177.439 9.86159C231.474 38.806 255.668 1.01748 302.445 27.5498C375.913 69.2223 269.379 147.347 323.414 185.136"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M497.871 153.699C544.353 138.871 572.992 141.761 630.162 181.154C666.844 206.43 689.952 272.919 673.978 335.78"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M500.029 152.668C526.226 143.763 549.064 139.87 573.981 147.207C589.273 151.709 601.969 158.966 619.512 171.482C624.867 175.285 630.575 179.086 636.599 183.139C652.751 193.998 666.686 211.101 675.675 235.787C687.036 266.985 687.678 307.491 674.67 336.362"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M502.186 151.637C528.128 142.265 553.125 137.203 579.52 145.353C596.514 150.6 607.389 158.242 625.361 172.65C630.831 177.001 636.762 180.998 643.037 185.124C659.891 196.197 674.755 212.423 683.972 239.908C694.781 272.138 692.132 315.591 675.363 336.944"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M504.343 150.605C530.03 140.767 557.182 134.551 585.059 143.498C603.752 149.497 612.769 157.561 631.21 173.817C636.953 178.699 643.059 183.142 649.474 187.108C667.032 198.395 682.858 213.725 692.269 244.028C702.59 277.261 696.586 323.69 676.055 337.525"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M506.501 149.574C531.932 139.269 561.236 131.91 590.599 141.643C610.989 148.402 618.18 156.843 637.059 174.984C642.843 180.315 649.161 185.043 655.911 189.092C674.173 200.594 690.993 215.011 700.566 248.148C710.45 282.363 701.04 331.789 676.748 338.107"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M508.658 148.543C533.834 137.772 565.286 129.279 596.138 139.789C618.224 147.313 623.593 156.122 642.907 176.151C648.705 182.164 655.325 186.731 662.348 191.077C681.313 202.793 699.159 216.286 708.863 252.269C718.35 287.452 705.494 339.889 677.441 338.689"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M510.816 147.512C535.736 136.274 569.335 126.654 601.677 137.934C625.458 146.228 629.008 155.399 648.756 177.318C654.667 183.879 661.512 188.642 668.786 193.061C688.454 204.992 707.356 217.552 717.16 256.389C726.283 292.53 709.948 347.988 678.133 339.27"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M512.974 146.48C537.638 134.776 573.381 124.035 607.216 136.079C632.689 145.147 634.424 154.674 654.605 178.485C660.63 185.594 667.7 190.552 675.223 195.045C695.595 207.19 715.581 218.811 725.456 260.509C734.241 297.602 714.402 356.087 678.826 339.852"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M515.131 145.449C539.54 133.278 577.426 121.42 612.755 134.225C639.92 144.071 639.843 153.948 660.454 179.653C666.593 187.31 673.887 192.464 681.66 197.03C702.736 209.389 723.832 220.064 733.753 264.63C742.222 302.67 718.855 364.186 679.519 340.434"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M517.289 144.418C541.442 131.78 581.469 118.808 618.294 132.37C647.149 142.997 645.263 153.22 666.302 180.82C672.557 189.024 680.074 194.374 688.097 199.014C709.876 211.588 732.109 221.313 742.05 268.749C750.22 307.735 723.309 372.285 680.211 341.015"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M519.446 143.387C543.344 130.283 585.511 116.2 623.833 130.516C654.377 141.926 650.685 152.493 672.151 181.987C678.521 190.739 686.262 196.286 694.535 200.999C717.017 213.787 740.41 222.559 750.347 272.87C758.233 312.798 727.763 380.384 680.904 341.597"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M521.604 142.355C545.246 128.785 589.552 113.594 629.373 128.661C661.604 140.857 656.166 151.724 678 183.154C684.503 192.442 692.449 198.197 700.972 202.983C724.158 215.986 748.732 223.802 758.644 276.99C766.26 317.861 732.217 388.483 681.596 342.178"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M523.761 141.324C547.148 127.287 593.592 110.99 634.911 126.807C668.83 139.791 661.586 151 683.848 184.321C690.466 194.157 698.637 200.108 707.409 204.968C731.298 218.184 757.076 225.044 766.94 281.111C774.297 322.923 736.671 396.583 682.289 342.76"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M525.919 140.293C549.05 125.789 597.631 108.387 640.451 124.953C676.055 138.727 667.005 150.277 689.697 185.489C696.429 195.873 704.824 202.019 713.846 206.953C738.439 220.383 765.439 226.284 775.237 285.231C782.344 327.987 741.125 404.682 682.981 343.342"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M528.076 139.262C550.952 124.292 601.67 105.786 645.99 123.098C683.279 137.663 672.425 149.552 695.546 186.656C702.393 197.589 711.012 203.93 720.284 208.937C745.58 222.582 773.821 227.523 783.534 289.351C790.399 333.05 745.579 412.781 683.674 343.924"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M530.234 138.23C552.854 122.793 605.673 103.273 651.529 121.243C690.474 136.675 677.845 148.828 701.394 187.823C708.356 199.304 717.199 205.84 726.721 210.921C752.72 224.78 782.219 228.762 791.831 293.471C798.461 338.114 750.032 420.88 684.367 344.505"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M532.391 137.199C554.755 121.296 609.718 100.654 657.068 119.389C697.703 135.599 683.264 148.104 707.243 188.99C714.32 201.02 723.386 207.752 733.158 212.906C759.861 226.98 790.634 230.001 800.127 297.592C806.53 343.18 754.486 428.98 685.059 345.087"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M534.549 136.167C556.658 119.798 613.763 98.0347 662.607 117.534C704.933 134.522 688.684 147.38 713.092 190.157C720.284 202.736 729.574 209.663 739.596 214.89C767.002 229.178 799.065 231.239 808.424 301.712C814.604 348.246 758.94 437.078 685.752 345.668"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M536.706 135.136C558.559 118.3 617.808 95.4158 668.146 115.679C712.162 133.445 694.104 146.656 718.941 191.324C726.247 204.452 735.761 211.574 746.033 216.874C774.142 231.377 807.51 232.478 816.721 305.832C822.684 353.314 763.394 445.178 686.444 346.25"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M538.863 134.105C560.461 116.803 621.853 92.7968 673.685 113.825C719.392 132.368 699.523 145.932 724.789 192.492C752.534 243.619 812.664 205.88 825.018 309.953C830.767 358.384 767.848 453.277 687.137 346.832"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M703.515 369.76C713.674 382.359 716.848 389.322 743.516 398.183C760.478 403.82 796.851 397.55 813.994 367.167C836.157 327.888 830.205 284.403 809.777 257.248"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M703.047 369.105C707.57 375.631 709.813 381.728 712.96 387.433C715.546 392.124 719.457 396.86 728.436 401.789C733.264 404.511 738.488 406.469 743.919 407.591C754.332 409.478 765.038 408.993 775.236 406.172C789.645 402.232 806.513 393.508 817.169 377.096C825.075 364.67 830.147 350.666 832.027 336.071C835.746 307.529 827.782 278.043 811.498 258.8"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M702.579 368.449C707.209 376.024 708.621 383.579 710.609 391.249C712.09 396.961 715.173 403.482 725.85 410.126C731.287 413.343 736.161 416.037 744.323 416.998C755.078 418.229 765.97 417.454 776.441 414.715C790.426 410.891 808.654 402.885 820.344 387.025C829.473 374.638 835.144 358.385 836.987 343.204C840.621 313.267 831.88 280.351 813.219 260.352"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M702.111 367.794C706.847 376.418 707.552 385.34 708.258 395.066C708.742 401.728 710.889 410.104 723.264 418.464C729.67 422.517 734.863 425.86 744.726 426.405C755.797 427.056 766.902 425.995 777.646 423.258C791.207 419.549 810.843 412.265 823.518 396.954C833.724 384.626 840.082 366.747 841.947 350.337C845.507 319.008 835.977 282.658 814.94 261.904"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M701.643 367.139C706.485 376.811 706.59 387.097 705.908 398.882C705.467 406.492 706.605 416.726 720.677 426.802C728.053 431.691 733.555 435.733 745.129 435.812C756.497 435.938 767.833 434.59 778.851 431.8C791.988 428.207 813.076 421.654 826.693 406.883C838.004 394.612 845.024 375.109 846.907 357.469C850.399 324.749 840.074 284.966 816.661 263.455"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M701.175 366.484C706.123 377.204 705.696 388.877 703.557 402.698C702.23 411.272 702.321 423.348 718.091 435.139C726.436 440.865 732.246 445.648 745.533 445.219C757.185 444.862 768.763 443.226 780.056 440.342C792.769 436.866 815.349 431.057 829.868 416.811C842.312 404.601 849.968 383.472 851.867 364.602C855.298 330.492 844.172 287.273 818.382 265.007"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M700.707 365.829C705.762 377.597 704.842 390.685 701.206 406.514C699.012 416.069 698.037 429.97 715.504 443.477C724.818 450.039 730.94 455.598 745.936 454.626C757.865 453.817 769.692 451.895 781.261 448.885C793.55 445.524 817.655 440.476 833.042 426.74C846.645 414.598 854.915 391.835 856.827 371.735C860.202 336.235 848.269 289.58 820.103 266.558"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M700.239 365.174C705.4 377.991 704.011 392.515 698.856 410.331C695.803 420.881 693.753 436.592 712.918 451.814C723.201 459.213 729.639 465.577 746.34 464.034C758.54 462.801 770.621 460.592 782.466 457.428C794.332 454.183 819.988 449.913 836.217 436.669C850.999 424.606 859.865 400.198 861.787 378.868C865.111 341.978 852.367 291.888 821.825 268.11"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M699.771 364.519C705.038 378.383 703.192 394.362 696.505 414.147C692.599 425.703 689.468 443.214 710.331 460.151C721.583 468.387 728.343 475.58 746.743 473.441C759.211 471.807 771.55 469.311 783.671 465.97C795.113 462.841 822.343 459.368 839.391 446.598C855.37 434.628 864.816 408.561 866.746 386C870.022 347.722 856.464 294.195 823.545 269.662"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M699.303 363.863C704.676 378.777 702.381 396.223 694.154 417.963C689.397 430.534 685.184 449.836 707.745 468.489C719.966 477.56 727.053 485.603 747.147 482.848C760.65 480.996 774.898 477.179 784.876 474.512C795.894 471.499 824.717 468.842 842.566 456.526C859.758 444.665 869.769 416.925 871.707 393.133C874.937 353.466 860.562 296.502 825.267 271.213"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M698.835 363.209C704.315 379.17 701.574 398.095 691.804 421.779C686.196 435.372 680.9 456.458 705.159 476.827C718.349 486.734 725.769 495.643 747.55 492.255C761.714 490.052 776.299 485.662 786.081 483.055C796.675 480.158 827.104 478.333 845.741 466.456C864.158 454.719 874.723 425.289 876.667 400.266C879.855 359.21 864.659 298.81 826.988 272.765"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M698.367 362.554C703.953 379.563 700.77 399.975 689.453 425.595C682.995 440.215 676.616 463.081 702.572 485.164C716.732 495.908 724.49 505.696 747.953 501.662C762.781 499.113 777.699 494.144 787.286 491.598C797.456 488.816 829.5 487.84 848.916 476.385C868.568 464.789 879.679 433.653 881.627 407.399C884.774 364.955 868.756 301.118 828.709 274.317"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M697.9 361.899C703.591 379.957 699.968 401.862 687.102 429.412C679.794 445.062 672.332 469.703 699.986 493.502C715.115 505.082 723.216 515.762 748.357 511.07C763.852 508.178 779.1 502.626 788.491 500.14C798.237 497.475 831.905 497.363 852.09 486.314C872.987 474.875 884.637 442.017 886.587 414.531C889.696 370.7 872.854 303.425 830.43 275.869"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M697.432 361.244C703.23 380.35 699.166 403.753 684.751 433.228C676.592 449.912 668.047 476.325 697.399 501.839C713.497 514.256 721.947 525.838 748.76 520.477C764.925 517.245 780.5 511.108 789.695 508.683C799.019 506.133 834.314 506.9 855.265 496.242C877.411 484.977 889.595 450.38 891.547 421.664C894.619 376.444 876.951 305.733 832.151 277.42"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M696.964 360.588C702.868 380.743 698.365 405.648 682.401 437.044C673.391 454.763 663.763 482.947 694.813 510.176C711.88 523.43 720.682 535.922 749.164 529.884C766.001 526.314 781.901 519.59 790.9 517.225C799.8 514.791 836.726 516.449 858.44 506.171C881.84 495.094 894.555 458.744 896.507 428.797C899.544 382.188 881.049 308.04 833.872 278.972"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M696.496 359.933C702.506 381.136 697.564 407.547 680.05 440.861C670.189 459.618 659.479 489.569 692.227 518.514C710.263 532.604 719.42 546.014 749.567 539.291C767.079 535.386 783.302 528.073 792.105 525.768C800.581 523.45 839.14 526.011 861.615 516.1C886.271 505.227 899.516 467.108 901.467 435.93C904.47 387.933 885.146 310.348 835.593 280.524"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M696.028 359.278C702.144 381.529 696.763 409.447 677.699 444.676C666.986 464.473 655.195 496.191 689.64 526.852C708.645 541.777 718.162 556.112 749.97 548.698C768.159 544.458 784.702 536.555 793.31 534.31C801.362 532.108 841.554 535.582 864.789 526.029C890.705 515.373 904.477 475.472 906.426 443.062C909.397 393.678 889.243 312.655 837.314 282.075"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M695.56 358.623C701.783 381.923 695.962 411.351 675.349 448.493C663.784 469.331 650.911 502.813 687.054 535.189C707.028 550.952 716.907 566.217 750.374 558.105C769.241 553.533 786.103 545.037 794.515 542.853C802.143 540.767 843.968 545.164 867.964 535.958C895.14 525.532 909.439 483.836 911.387 450.195C914.325 399.423 893.341 314.962 839.036 283.627"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M695.092 357.968C701.421 382.316 695.161 413.256 672.998 452.309C660.581 474.189 646.627 509.435 684.467 543.527C705.411 560.126 715.655 576.326 750.778 567.513C770.325 562.608 787.503 553.519 795.72 551.396C802.924 549.425 846.38 554.753 871.139 545.887C899.575 535.703 914.403 492.2 916.346 457.328C919.254 405.167 897.438 317.27 840.757 285.179"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M694.624 357.313C701.059 382.709 694.359 415.162 670.647 456.125C657.378 479.048 642.342 516.057 681.881 551.864C703.794 569.299 714.406 586.439 751.181 576.919C771.41 571.683 788.904 562.001 796.925 559.938C803.705 558.083 848.79 564.35 874.313 555.815C904.009 545.885 919.366 500.564 921.306 464.46C924.184 410.912 901.535 319.577 842.478 286.73"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M694.156 356.658C700.698 383.102 693.558 417.07 668.297 459.941C654.175 483.908 638.058 522.679 679.295 560.202C702.176 578.473 713.159 596.557 751.584 586.327C772.496 580.759 790.305 570.483 798.13 568.48C804.487 566.742 851.199 573.954 877.488 565.744C908.443 556.077 924.331 508.928 926.266 471.593C929.115 416.656 905.633 321.885 844.199 288.282"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M693.688 356.003C700.336 383.496 692.756 418.98 665.946 463.758C650.971 488.77 633.774 529.302 676.708 568.54C700.559 587.648 711.914 606.678 751.988 595.735C773.584 589.837 791.705 578.966 799.335 577.024C805.268 575.401 853.605 583.565 880.663 575.674C912.875 566.279 929.296 517.293 931.226 478.727C934.046 422.402 909.73 324.193 845.92 289.834"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M693.22 355.348C699.974 383.889 691.955 420.89 663.595 467.575C647.767 493.631 629.49 535.924 674.122 576.878C698.942 596.821 710.671 616.802 752.391 605.142C774.673 598.914 793.106 587.448 800.54 585.566C806.049 584.059 856.009 593.18 883.837 585.602C917.306 576.489 934.262 525.657 936.186 485.859C938.978 428.146 913.828 326.5 847.641 291.386"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M692.752 354.693C699.612 384.282 691.153 422.8 661.245 471.39C644.563 498.493 625.206 542.546 671.535 585.215C697.325 605.995 709.431 626.929 752.795 614.548C775.762 607.991 794.507 595.93 801.745 594.108C806.83 592.717 858.41 602.8 887.012 595.531C921.735 586.706 939.228 534.02 941.146 492.992C943.91 433.89 917.925 328.807 849.362 292.937"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M692.284 354.037C699.251 384.675 690.35 424.711 658.894 475.206C641.358 503.355 620.921 549.168 668.949 593.552C695.707 615.168 708.191 637.057 753.198 623.955C776.853 617.069 795.907 604.412 802.95 602.65C807.611 601.376 860.808 612.424 890.187 605.459C926.161 596.931 944.194 542.384 946.106 500.124C948.843 439.635 922.023 331.114 851.083 294.489"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M691.816 353.382C698.889 385.069 689.548 426.624 656.543 479.023C638.154 508.218 616.637 555.79 666.363 601.89C694.09 624.343 706.953 647.189 753.601 633.363C777.944 626.148 797.308 612.895 804.155 611.193C808.392 610.034 863.203 622.053 893.361 615.389C930.586 607.163 949.161 550.748 951.066 507.257C953.776 445.379 926.12 333.422 852.804 296.041"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M691.349 352.727C698.527 385.462 688.746 428.536 654.193 482.839C634.95 513.081 612.353 562.412 663.776 610.227C692.473 633.516 705.717 657.322 754.005 642.77C779.037 635.226 798.709 621.377 805.36 619.735C809.174 618.692 865.595 631.684 896.536 625.317C935.009 617.4 954.219 559.116 956.027 514.39C958.837 451.129 930.218 335.729 854.526 297.592"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M690.881 352.072C698.166 385.855 687.943 430.449 651.842 486.655C631.745 517.944 608.069 569.034 661.19 618.565C690.856 642.69 704.482 667.458 754.408 652.177C780.129 644.305 800.109 629.859 806.565 628.278C809.955 627.351 867.985 641.319 899.711 635.246C939.429 627.643 959.178 567.479 960.986 521.523C963.759 456.873 934.315 338.037 856.247 299.144"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M690.413 351.417C697.804 386.248 687.14 432.363 649.491 490.471C628.54 522.808 603.785 575.656 658.603 626.902C689.238 651.864 703.248 677.594 754.812 661.584C781.223 653.383 801.51 638.341 807.77 636.82C810.736 636.009 870.372 650.956 902.886 645.175C943.847 637.891 964.137 575.843 965.946 528.655C968.68 462.616 938.412 340.344 857.968 300.695"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M689.945 350.762C697.442 386.642 686.338 434.277 647.141 494.288C625.335 527.673 599.5 582.278 656.017 635.24C687.621 661.038 702.015 687.733 755.215 670.992C782.317 662.463 802.91 646.823 808.974 645.363C811.517 644.668 872.756 660.597 906.06 655.104C948.263 648.144 969.097 584.207 970.906 535.788C973.601 468.361 942.51 342.652 859.689 302.248"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M689.477 350.107C697.08 387.035 685.535 436.191 644.79 498.104C622.13 532.537 595.216 588.9 653.43 643.577C686.004 670.212 700.784 697.873 755.618 680.399C783.411 671.542 804.311 655.305 810.179 653.906C812.298 653.326 875.138 670.239 909.235 665.033C952.677 658.4 974.056 592.57 975.866 542.921C978.522 474.104 946.607 344.959 861.41 303.799"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M689.009 349.452C696.718 387.428 684.731 438.106 642.439 501.92C618.924 537.401 590.932 595.522 650.844 651.915C684.386 679.386 699.553 708.014 756.022 689.806C784.507 680.621 805.711 663.788 811.384 662.448C813.079 661.985 877.517 679.883 912.41 674.962C957.089 668.66 979.015 600.934 980.826 550.054C983.444 479.848 950.704 347.267 863.131 305.351"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M688.541 348.797C696.357 387.821 684.048 440.101 640.089 505.737C615.786 542.31 586.648 602.145 648.258 660.253C682.769 688.56 698.323 718.156 756.425 699.213C785.602 689.701 807.112 672.27 812.589 670.991C813.861 670.643 879.895 689.529 915.585 684.891C961.498 678.924 983.974 609.297 985.786 557.187C988.365 485.592 954.802 349.574 864.852 306.903"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M688.073 348.142C695.995 388.214 683.205 441.989 637.738 509.553C612.558 547.16 582.364 608.767 645.671 668.59C681.152 697.733 697.094 728.299 756.829 708.62C786.698 698.78 808.513 680.752 813.794 679.533C814.642 679.301 882.269 699.176 918.759 694.819C965.906 689.19 988.934 617.661 990.746 564.319C993.287 491.335 958.899 351.882 866.573 308.454"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M687.605 347.486C695.633 388.607 682.361 443.878 635.387 513.368C609.33 552.01 578.079 615.388 643.085 676.927C679.534 706.907 695.895 738.53 757.232 718.027C787.809 707.902 809.913 689.234 814.999 688.075C815.423 687.96 884.641 708.825 921.934 704.748C970.311 699.459 993.893 626.024 995.706 571.452C998.208 497.079 962.996 354.189 868.294 310.006"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M687.137 346.831C695.272 389 681.518 445.767 633.036 517.185C606.103 556.86 573.795 622.01 640.498 685.264C677.917 716.081 694.638 748.588 757.636 727.434C788.892 716.938 811.314 697.716 816.204 696.618C816.204 696.618 887.012 718.475 925.108 714.677C974.715 709.731 998.852 634.387 1000.67 578.584C1003.13 502.822 967.094 356.496 870.015 311.557"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M756.264 221.61C807.082 222.845 872.775 188.865 867.817 319.227"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M757.194 221.579C785.419 218.988 820.501 206.67 845.416 221.898C860.457 231.09 868.026 248.713 871.462 278.789C872.986 292.131 869.49 303.011 868.374 319.783"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M758.123 221.548C786.331 215.679 823.645 201.26 850.738 218.5C867.743 229.322 873.109 249.267 876.391 279.909C878.013 295.049 870.543 303.084 868.932 320.339"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M759.053 221.517C787.243 212.37 826.779 195.868 856.059 215.104C875.025 227.563 878.165 249.824 881.321 281.03C883.033 297.967 871.597 303.157 869.49 320.895"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M759.983 221.485C788.155 209.06 829.906 190.486 861.381 211.706C882.302 225.81 883.203 250.383 886.25 282.149C888.046 300.886 872.651 303.23 870.048 321.451"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M760.912 221.455C789.067 205.752 833.027 185.114 866.702 208.31C889.575 224.064 888.227 250.945 891.178 283.27C893.053 303.807 873.704 303.304 870.605 322.007"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M761.842 221.424C789.978 202.443 836.144 179.747 872.023 204.913C896.845 222.322 893.241 251.507 896.108 284.391C898.055 306.728 874.757 303.377 871.163 322.563"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M762.771 221.393C790.89 199.134 839.258 174.385 877.345 201.516C904.113 220.583 898.247 252.069 901.037 285.511C903.051 309.649 875.811 303.45 871.721 323.119"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M763.701 221.362C791.802 195.825 842.369 169.026 882.666 198.119C911.378 218.847 903.246 252.633 905.966 286.631C908.042 312.571 876.865 303.522 872.279 323.675"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M764.631 221.331C792.714 192.516 845.477 163.67 887.988 194.721C918.642 217.113 908.239 253.196 910.895 287.751C913.027 315.492 877.918 303.595 872.837 324.231"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M765.56 221.301C793.626 189.208 848.584 158.316 893.309 191.325C925.904 215.381 913.229 253.76 915.824 288.872C918.008 318.415 878.972 303.669 873.394 324.788"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M766.49 221.27C794.538 185.899 851.689 152.964 898.63 187.928C933.165 213.65 918.215 254.324 920.753 289.992C922.984 321.338 880.025 303.742 873.952 325.344"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M767.419 221.239C795.45 182.59 854.793 147.613 903.952 184.531C940.425 211.921 923.198 254.888 925.683 291.112C927.956 324.26 881.079 303.815 874.51 325.899"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M768.349 221.208C796.362 179.281 857.895 142.264 909.273 181.134C947.684 210.193 928.179 255.452 930.612 292.232C932.924 327.183 882.133 303.888 875.068 326.456"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M769.279 221.177C797.273 175.972 860.918 137.019 914.595 177.737C954.882 208.543 933.157 256.016 935.541 293.352C937.887 330.106 883.186 303.96 875.625 327.011"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M770.208 221.146C798.185 172.663 864.03 131.655 919.916 174.339C962.147 206.805 938.133 256.58 940.47 294.473C942.847 333.029 884.24 304.034 876.183 327.567"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M771.137 221.115C799.097 169.355 867.142 126.293 925.237 170.943C969.412 205.068 943.108 257.144 945.399 295.593C947.803 335.953 885.293 304.107 876.741 328.124"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M772.068 221.085C800.009 166.046 870.255 120.929 930.559 167.546C976.678 203.33 948.082 257.708 950.328 296.713C952.756 338.877 886.347 304.18 877.299 328.68"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M772.997 221.053C800.921 162.737 873.367 115.566 935.881 164.149C983.944 201.592 952.987 258.275 955.257 297.834C957.63 341.804 887.4 304.253 877.856 329.236"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M773.927 221.022C801.833 159.428 876.479 110.202 941.202 160.752C991.209 199.855 957.991 258.837 960.186 298.954C962.612 344.725 888.454 304.326 878.414 329.792"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
        <path
          d="M774.856 220.991C802.744 156.119 879.592 104.839 946.523 157.355C998.475 198.117 962.996 259.399 965.115 300.074C967.594 347.647 889.507 304.399 878.972 330.348"
          stroke="#E8E5DE"
          strokeWidth="1.41732"
          strokeMiterlimit="10"
        />
      </g>
    </svg>
  )
}
