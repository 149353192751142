import { logoProps } from "./"

export const JG24LogoMobile = ({ className }: logoProps) => {
  return (
    <svg
      className={className}
      width="315"
      height="259"
      viewBox="0 0 315 259"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1196_712)">
        <path
          d="M8.607 5.0042H13.0775V16.7806C13.0775 19.1158 11.1425 21.0842 8.70706 21.0842C6.47191 21.0842 4.5371 19.2826 4.43686 16.9807L0 17.7481C0.467034 22.1517 4.20335 25.5878 8.74046 25.5878C13.6111 25.5878 17.5812 21.6513 17.5812 16.7806V0.500488H8.607V5.0042Z"
          fill="white"
        />
        <path
          d="M31.1239 0.500488H26.9537L20.4152 22.4186C20.2817 22.6854 20.2483 22.9523 20.2483 23.2525V25.0874H24.8855V22.9523L25.5526 20.4837H32.6919L33.3924 22.9523V25.0874H37.8961V23.2192C37.8961 22.9189 37.7626 22.6187 37.696 22.3518L31.1239 0.500488ZM26.7202 16.1801L29.0556 7.50627L31.4908 16.1801H26.7202Z"
          fill="white"
        />
        <path
          d="M53.5407 0.500369L45.534 11.3093V0.500369H41.0637V25.0873H45.534V18.7487L49.1036 13.9782L54.2412 22.3517V25.0873H58.7115V22.1182C58.7115 21.7512 58.6115 21.4176 58.5114 21.1173L51.8058 10.0082L58.7115 0.500366H53.5407V0.500369Z"
          fill="white"
        />
        <path
          d="M69.5187 0C64.648 0 60.7115 3.93659 60.7115 8.80727V16.7805C60.7115 21.6512 64.648 25.5878 69.5187 25.5878C74.3894 25.5878 78.3594 21.6512 78.3594 16.7805V8.80727C78.3594 3.93659 74.3894 0 69.5187 0ZM73.8889 16.7805C73.8889 19.1157 71.9207 21.0841 69.5187 21.0841C67.1501 21.0841 65.1818 19.1157 65.1818 16.7805V8.80727C65.1818 6.47207 67.1501 4.50383 69.4854 4.50383C71.9207 4.50383 73.8889 6.43865 73.8889 8.84066V16.7805Z"
          fill="white"
        />
        <path
          d="M96.5392 11.276C97.4732 10.1084 98.0738 8.64061 98.0738 7.07264C98.0738 3.43636 95.138 0.500488 91.535 0.500488H81.6936V25.0874H91.535C95.8386 25.0874 99.3414 21.5846 99.3414 17.2476C99.3414 14.879 98.2405 12.7439 96.5392 11.276ZM86.1639 5.03759H91.3681C92.6026 5.03759 93.57 6.00508 93.57 7.23934C93.57 8.47376 92.6026 9.50789 91.4016 9.50789H86.1639V5.03759ZM91.435 20.6171H86.1639V13.6446H91.3681C93.2697 13.6446 94.8377 15.2126 94.8711 17.181C94.8711 19.0825 93.3031 20.6171 91.435 20.6171Z"
          fill="white"
        />
        <path
          d="M115.786 11.7432C114.685 10.9092 113.384 10.2418 112.016 9.90826L108.58 9.10766C107.613 8.87411 106.812 8.07335 106.812 7.00582V6.4387C106.812 5.33792 107.746 4.43709 108.814 4.43709H110.448C111.383 4.43709 112.217 5.10424 112.45 5.93827C112.484 6.07173 112.65 6.97257 112.65 6.97257L117.02 6.33864C116.82 2.80245 113.918 0.0334473 110.382 0.0334473H109.147C105.478 0.0334473 102.509 3.00256 102.509 6.70564C102.509 9.84163 104.644 12.6104 107.513 13.2778L111.182 14.145C113.184 14.6121 114.252 16.1134 114.252 17.7814C114.252 19.4827 112.75 21.0842 111.049 21.0842H108.78C107.246 21.0842 105.878 19.9498 105.745 18.4486L105.644 17.7147L101.174 18.382C101.675 22.3852 104.677 25.521 109.981 25.5544C115.252 25.5544 118.522 21.9514 118.588 17.648C118.655 15.0791 117.488 13.0775 115.786 11.7432Z"
          fill="white"
        />
        <path
          d="M136.435 11.276C137.369 10.1084 137.97 8.64061 137.97 7.07264C137.97 3.43636 135.034 0.500488 131.431 0.500488H121.589V25.0874H131.431C135.734 25.0874 139.237 21.5846 139.237 17.2476C139.237 14.879 138.136 12.7439 136.435 11.276ZM126.06 5.03759H131.264C132.498 5.03759 133.466 6.00508 133.466 7.23934C133.466 8.47376 132.498 9.50789 131.297 9.50789H126.06V5.03759ZM131.331 20.6171H126.06V13.6446H131.264C133.166 13.6446 134.734 15.2126 134.767 17.181C134.767 19.0825 133.199 20.6171 131.331 20.6171Z"
          fill="white"
        />
        <path
          d="M142.738 25.0874H160.386V20.5837H147.209V14.2119H156.082V9.77481H147.209V5.0042H160.386V0.500488H142.738V25.0874Z"
          fill="white"
        />
        <path
          d="M181.535 8.24021C181.535 3.97005 178.032 0.500488 173.762 0.500488C169.492 0.500488 163.887 0.500488 163.887 0.500488V25.0874H168.358V16.08H173.895L177.065 22.6854V25.0874H181.535V22.0849C181.535 21.7513 181.435 21.4177 181.335 21.1174L178.032 14.7455C180.134 13.3444 181.535 10.9424 181.535 8.24021ZM173.695 11.7432C171.76 11.7432 168.291 11.7432 168.291 11.7432V4.87073C168.291 4.87073 171.76 4.87073 173.695 4.87073C175.564 4.87073 177.098 6.40531 177.098 8.34027C177.098 10.2084 175.564 11.7432 173.695 11.7432Z"
          fill="white"
        />
        <path
          d="M195.044 16.3469H197.679V16.7805C197.679 19.4161 195.578 21.0841 193.176 21.0841C190.807 21.0841 188.839 19.1157 188.839 16.7805V8.80727C188.839 6.40526 190.807 4.47044 193.142 4.47044C195.578 4.50383 197.546 6.43865 197.546 8.84066L201.949 7.94C201.516 3.46954 197.746 0 193.176 0C188.305 0 184.368 3.93659 184.368 8.80727V16.7805C184.368 21.6512 188.305 25.5878 193.176 25.5878C194.81 25.5878 197.245 24.6537 197.713 23.3859V25.0873H202.016V12.3435H195.044V16.3469Z"
          fill="white"
        />
        <path
          d="M218.461 11.7432C217.36 10.9092 216.059 10.2418 214.691 9.90826L211.255 9.10766C210.288 8.87411 209.487 8.07335 209.487 7.00582V6.4387C209.487 5.33792 210.421 4.43709 211.489 4.43709H213.123C214.058 4.43709 214.892 5.10424 215.125 5.93827C215.158 6.07173 215.325 6.97257 215.325 6.97257L219.696 6.33864C219.495 2.80245 216.593 0.0334473 213.057 0.0334473H211.822C208.153 0.0334473 205.183 3.00256 205.183 6.70564C205.183 9.84163 207.319 12.6104 210.188 13.2778L213.857 14.145C215.859 14.6121 216.927 16.1134 216.927 17.7814C216.927 19.4827 215.425 21.0842 213.724 21.0842H211.455C209.921 21.0842 208.553 19.9498 208.419 18.4486L208.319 17.7147L203.849 18.382C204.349 22.3852 207.352 25.521 212.656 25.5544C217.927 25.5544 221.197 21.9514 221.264 17.648C221.33 15.0791 220.163 13.0775 218.461 11.7432Z"
          fill="white"
        />
        <path
          d="M234.273 16.3469H236.908V16.7805C236.908 19.4161 234.807 21.0841 232.405 21.0841C230.036 21.0841 228.068 19.1157 228.068 16.7805V8.80727C228.068 6.40526 230.036 4.47044 232.371 4.47044C234.807 4.50383 236.775 6.43865 236.775 8.84066L241.178 7.94C240.745 3.46954 236.975 0 232.405 0C227.534 0 223.597 3.93659 223.597 8.80727V16.7805C223.597 21.6512 227.534 25.5878 232.405 25.5878C234.039 25.5878 236.474 24.6537 236.942 23.3859V25.0873H241.245V12.3435H234.273V16.3469Z"
          fill="white"
        />
        <path
          d="M254.788 0.500488H250.618L244.079 22.4186C243.945 22.6854 243.912 22.9523 243.912 23.2525V25.0874H248.549V22.9523L249.216 20.4837H256.356L257.056 22.9523V25.0874H261.56V23.2192C261.56 22.9189 261.427 22.6187 261.36 22.3518L254.788 0.500488ZM250.384 16.1801L252.719 7.50627L255.155 16.1801H250.384Z"
          fill="white"
        />
        <path
          d="M278.206 0.500488H260.558V5.0042H267.063V25.0874H271.634V5.0042H278.206V0.500488Z"
          fill="white"
        />
        <path
          d="M287.579 0.500488H283.409L276.87 22.4186C276.737 22.6854 276.703 22.9523 276.703 23.2525V25.0874H281.341V22.9523L282.008 20.4837H289.147L289.848 22.9523V25.0874H294.351V23.2192C294.351 22.9189 294.218 22.6187 294.151 22.3518L287.579 0.500488ZM283.175 16.1801L285.511 7.50627L287.946 16.1801H283.175Z"
          fill="white"
        />
        <path
          d="M310.63 0.500488V16.1134L301.822 0.500488H297.519V25.0874H301.822V9.1409L310.797 25.0874H315V0.500488H310.63Z"
          fill="white"
        />
        <path
          d="M148.372 258.398H0L0.119694 257.131C1.51915 242.337 4.17633 230.192 8.24315 220.002C12.1205 210.287 17.4164 202.042 24.4338 194.797C37.9198 180.872 57.3112 171.144 81.8615 158.828C83.3125 158.1 84.7838 157.362 86.2759 156.612C101.654 149.053 108.812 138.338 108.812 122.885C108.812 113.588 105.209 104.864 98.6678 98.3227C92.1261 91.781 83.4029 88.1783 74.1052 88.1783C65.8796 88.1783 58.2537 91.1397 52.0521 96.7424C46.2524 101.982 41.9427 109.405 39.9166 117.644L38.2761 124.205L0.308776 117.78L0.394415 116.723C1.88856 98.2487 10.2373 81.1277 23.9026 68.5135C37.6082 55.8623 55.4372 48.8948 74.1052 48.8948C93.8951 48.8948 112.483 56.584 126.445 70.5458C140.407 84.5076 148.096 103.095 148.096 122.885C148.096 138.877 143.767 152.382 134.861 164.172C126.502 175.238 114.195 184.634 97.2346 192.897C95.0378 193.947 92.5026 195.053 89.8185 196.224C77.7935 201.469 61.5201 208.566 57.2457 219.114H148.372V258.398ZM2.54836 256.082H146.056V221.43H54.0822L54.5087 219.951C58.09 207.536 75.8935 199.771 88.8929 194.101C91.558 192.939 94.0751 191.841 96.228 190.812C130.032 174.343 145.781 152.757 145.781 122.885C145.781 103.714 138.332 85.7075 124.808 72.1829C111.283 58.6583 93.2767 51.2101 74.1052 51.2101C56.0209 51.2101 38.7498 57.9594 25.4731 70.2148C12.4972 82.1927 4.47013 98.3647 2.79553 115.853L36.5497 121.565L37.6694 117.087C39.8036 108.408 44.3606 100.571 50.5 95.0244C57.1341 89.0309 65.2967 85.863 74.1052 85.863C84.0213 85.863 93.3259 89.7065 100.305 96.6856C107.284 103.665 111.128 112.969 111.128 122.885C111.128 139.322 103.558 150.697 87.3065 158.685C85.8231 159.431 84.3513 160.17 82.8998 160.898C33.3862 185.737 8.35592 198.293 2.54836 256.082Z"
          fill="white"
        />
        <path
          d="M287.412 258.398H248.128V223.636H166.743V185.73L166.92 185.448L250.024 52.7571H287.412V184.629H315V223.636H287.412V258.398ZM250.443 256.082H285.096V221.321H312.685V186.944H285.096V55.0724H251.306L169.058 186.395V221.321H250.443V256.082ZM250.443 186.944H205.791L250.443 116.989V186.944ZM210.016 184.629H248.128V124.92L210.016 184.629Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1196_712">
          <rect width="315" height="258.568" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
